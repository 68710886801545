import { Link } from "react-router-dom";
function AppHeader() {
    return (
        <>
            <div className="appHeader bg-primary text-light">
                <div className="left">
                    {/* <Link className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </Link> */}
                    <Link to={'/profile-settings'} className="headerButton">
                        <img style={{width: '40px',height: '40px',objectFit: 'cover'}} src={(JSON.parse(localStorage.getItem('admin_data')).image === null) ? '/assets/img/logo.jpg' : process.env.REACT_APP_BACKEND_BASE_PATH+'/'+JSON.parse(localStorage.getItem('admin_data')).image} alt="Admin Pic" className="imaged" />
                    </Link>
                </div>
                <div className="pageTitle text-uppercase h2">
                    Lava
                </div>
                <div className="right">
                    <Link href="app-notifications.html" className="headerButton">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon>
                        <span className="badge badge-danger">4</span>
                    </Link>
                </div>
            </div>
        </>
    );
}
export default AppHeader;