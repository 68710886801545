import { Link } from "react-router-dom";
function WaiterList(props) {
    const waiterItems = props.waiters.map((waiter) =>
        <div key={waiter.id} className="col-6 mb-2">
            <Link to={`/waiter/${waiter.id}`}>
                <div className="blog-card">
                    <img src={(waiter.image === null) ? 'assets/img/placeholder.avif' : process.env.REACT_APP_BACKEND_BASE_PATH + '/' + waiter.image} alt={waiter.name} style={{ height: "120px", width: "100%", objectFit: "cover" }} className="imaged w-100" />
                    <div className="text">
                        <h4 className="title">{waiter.fname} {waiter.lname}</h4>
                    </div>
                </div>
            </Link>
        </div>
    );
    return (
        <>
            <div className="row">
                {waiterItems}
            </div>
        </>
    );
}
export default WaiterList;