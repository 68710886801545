import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
function AddOrder() {
    const [customerName, setCustomerName] = useState('');
    const [customerPhoneNo, setCustomerPhoneNo] = useState('');
    const [menus, setMenus] = useState({});
    const [userMenus, setUserMenus] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [quantity, setQuantity] = useState({});
    const [priceReCalculate, setPriceReCalculate] = useState(false);
    const [ searchResults, setSearchResults ] = useState({});
    const [alert, setAlert] = useState({
        alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
        alertMessage: ''
    });
    const [reloadRequired, setReloadRequired] = useState(false);
    let { tableId, tableName } = useParams();
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/menu-list').then(menuListResponse => {
                setMenus(menuListResponse.data.data);
                setSearchResults(menuListResponse.data.data);
            });
        });
    }, [reloadRequired]);
    useEffect(() => {
        let newPrice = 0;
        for (let index = 0; index < userMenus.length; index++) {
            newPrice = newPrice + (quantity[userMenus[index][0].id].quantity * quantity[userMenus[index][0].id].price);
        }
        setTotalPrice(newPrice);
    }, [priceReCalculate]);
    const addItem = (menuId, discountedPrice, e) => {
        const selectedMenuQuantity = Number(document.getElementById('qty' + menuId).value);
        if (selectedMenuQuantity > 0) {
            const newUserMenus = [...userMenus];
            const selectedMenu = menus.filter(item => item.id === menuId);
            setTotalPrice(totalPrice + (discountedPrice * selectedMenuQuantity));
            newUserMenus.push(selectedMenu);
            setUserMenus(newUserMenus);
            setSearchResults(menus.filter(item => item.id !== menuId));
            setMenus(menus.filter(item => item.id !== menuId));
            const prevMenuQuantity = { ...quantity };
            const newsMenuQuantity = {
                menu_id: menuId,
                quantity: selectedMenuQuantity,
                price: discountedPrice
            };
            prevMenuQuantity[menuId] = newsMenuQuantity;
            setQuantity(prevMenuQuantity);
        }
    }
    const changeQuantity = (discounted_price, menu_id, e) => {
        const menuQuantity = Number(e.target.value);
        const prevQuantity = { ...quantity };
        prevQuantity[menu_id] = {
            menu_id: menu_id,
            quantity: menuQuantity,
            price: discounted_price
        };
        setQuantity(prevQuantity);
        setPriceReCalculate(!priceReCalculate);
    }
    const removeItem = (menu_id, e) => {
        const newMenus = [...menus];
        const newUserMenus = [];
        for (let index = 0; index < userMenus.length; index++) {
            if (userMenus[index][0].id === menu_id) {
                newMenus[menu_id] = userMenus[index][0];
            } else {
                newUserMenus.push(userMenus[index]);
            }
        }
        setUserMenus(newUserMenus);
        setSearchResults(newMenus);
        setMenus(newMenus);
        setPriceReCalculate(!priceReCalculate);
    }
    const addOrder = (e) => {
        e.preventDefault();
        const menuExists = document.querySelector('input[name="menu_id[]"]');
        if (menuExists !== null) {
            document.getElementById('placeOrderBtn').setAttribute('disabled',true);
            const quantityArray = [];
            document.getElementsByClassName("orderQuantityBox").forEach((el) => {
                quantityArray.push(el.value);
            });
            const addOrderForm = document.getElementById('addOrderForm');
            const orderFormData = new FormData(addOrderForm);
            orderFormData.append("quantity_array",quantityArray);
            const APIClient = Axios.create({
                baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            });
            APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/add-order', orderFormData).then(addOrderResponse => {
                    if(addOrderResponse.status === 200) {
                        setAlert(
                            {
                                alertClass: 'z-3 offcanvas bg-success offcanvas-bottom cookies-box show visible',
                                alertMessage: 'Order Placed'
                            }
                        );
                        document.getElementById('placeOrderBtn').removeAttribute('disabled');
                    }else {
                        setAlert(
                            {
                                alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                alertMessage: 'Order Failed'
                            }
                        );
                        document.getElementById('placeOrderBtn').removeAttribute('disabled');
                    }
                    setCustomerName('');
                    setCustomerPhoneNo('');
                    setUserMenus([]);
                    setTotalPrice(0);
                    setQuantity({});
                    setReloadRequired(!reloadRequired);
                })
                .catch(function(error) {
                    setAlert(
                        {
                            alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                            alertMessage: 'Order Failed'
                        }
                    );
                    document.getElementById('placeOrderBtn').removeAttribute('disabled');
                });
            });
        } else {
            setAlert(
                {
                    alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                    alertMessage: 'Add Menu Item'
                }
            );
        }
    }
    const closeAlert = () => {
        setAlert(
            {
                alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
                alertMessage: ''
            }
        );
    }
    const SearchMenuItem = (e) => {
        const filteredItems = menus.filter(item => item.name.toLowerCase().includes((e.target.value).toLowerCase()));
        setSearchResults(filteredItems);
    }
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <Link to={'/table'} className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </Link>
                </div>
                <div className="pageTitle">
                    Table No: {tableName}
                </div>
                <div className="right">
                    <div className="headerButton" data-bs-toggle="modal" data-bs-target="#qrCodePanel">
                        <ion-icon name="qr-code-outline"></ion-icon>
                    </div>
                </div>
            </div>
            <form method="POST" action="#" onSubmit={addOrder} id="addOrderForm">
                <div id="appCapsule">
                    <div className="section my-3">
                        <input type="hidden" name="taken_by" value={localStorage.getItem('admin')} required />
                        <input type="hidden" name="table_id" value={tableId} required />
                        <div className="form-group basic">
                            <label className="label">Customer Name</label>
                            <div className="input-group mb-2">
                                <input name="customer_name" type="text" value={customerName || ''} className="form-control" autoComplete="off" autoFocus placeholder="Full Name (Not Mandatory)"
                                    onChange={(e) => { setCustomerName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Customer Phone Number</label>
                            <div className="input-group mb-2">
                                <input name="customer_phone_no" type="number" value={customerPhoneNo || ''} className="form-control" autoComplete="off" placeholder="Phone Number (Mandatory)"
                                    onChange={(e) => { setCustomerPhoneNo(e.target.value) }} required />
                            </div>
                        </div>
                        {((userMenus.length !== undefined) && (Object.keys(userMenus).length !== 0)) ?
                            <div className="transactions p-0">
                                {userMenus.map((menu) =>
                                    <div key={menu[0].id} className="mb-2">
                                        <div className="item pt-2">
                                            <div className="detail d-flex position-relative mb-2">
                                                <div>
                                                    <strong>{menu[0].name}</strong>
                                                    <strong className="mt-2">
                                                        <div>₹ {menu[0].discounted_price}</div>
                                                    </strong>
                                                    <strong className="mt-2">
                                                        Qty: <input type="number" min={1} className="orderQuantityBox" name="quantity[]" value={quantity[menu[0].id].quantity} onChange={(e) => { changeQuantity(Number(menu[0].discounted_price), menu[0].id, e) }} required />
                                                    </strong>
                                                </div>
                                                <input type="hidden" name="price[]" value={menu[0].discounted_price} onChange={(e) => { }} required />
                                                <input type="hidden" name="menu_id[]" value={menu[0].id} onChange={(e) => { }} required />
                                            </div>
                                            <div className="item p-0">
                                                <div data-bs-toggle="modal" data-bs-target={"#DialogImaged_" + menu[0].id} className="icon-wrapper  bg-danger p-2 m-1 rounded">
                                                    <ion-icon name="trash-outline"></ion-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade dialogbox" id={"DialogImaged_" + menu[0].id} data-bs-backdrop="static" tabIndex="-1" role="dialog">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="pt-3 text-center">
                                                        <img src={process.env.REACT_APP_BACKEND_BASE_PATH + '/' + menu[0].image} alt="Menu Item" style={{ width: '80px', height: '80px', objectFit: 'cover' }} className="imaged rounded mb-1" />
                                                    </div>
                                                    <div className="modal-header pt-2">
                                                        <h5 className="modal-title text-danger">Remove Item</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        Are you sure about that?
                                                    </div>
                                                    <div className="modal-footer">
                                                        <div className="btn-inline">
                                                            <button type="button" className="btn btn-text-secondary" data-bs-dismiss="modal">CANCEL</button>
                                                            <button onClick={(e) => { removeItem(menu[0].id, e) }} type="button" className="btn btn-text-danger" data-bs-dismiss="modal">CONFIRM</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                            : ''}
                        <h5 className="modal-title">Search & Add Menu</h5>
                        <div className="form-group searchbox mt-2">
                            <input onChange={SearchMenuItem} type="text" className="form-control" />
                            <i className="input-icon">
                                <ion-icon name="search-outline"></ion-icon>
                            </i>
                        </div>
                        <div className="my-4">
                            {(Object.keys(searchResults).length !== 0) ?
                                <div className="transactions p-0">
                                    {searchResults.map((menu) => (
                                        <div key={menu.id} className="mb-2">
                                            <div className="item">
                                                <div className="detail d-flex position-relative mb-2">
                                                    <div>
                                                        <img src={process.env.REACT_APP_BACKEND_BASE_PATH + '/' + menu.image} alt="img" style={{ width: '60px', height: '60px', objectFit: 'cover' }} className="image-block imaged" />
                                                    </div>
                                                    <div>
                                                        <strong>{menu.name}</strong>
                                                        <strong className="mt-2">
                                                            <div className="badge bg-danger px-3 me-2"><del>₹ {menu.regular_price}</del></div>
                                                            <div className="badge px-3 bg-success">₹ {menu.discounted_price}</div>
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className="item bg-primary p-2 ms-1" data-bs-toggle="modal" data-bs-target={'#DialogForm_' + menu.id}>
                                                    <div className="icon-wrapper">
                                                        <ion-icon name="add-outline"></ion-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Add Item Modal */}
                                            <div className="modal fade dialogbox" id={'DialogForm_' + menu.id} data-bs-backdrop="static" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Add Item</h5>
                                                        </div>
                                                        <div className="modal-body text-start mb-2">


                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="label">Enter Quantity</label>
                                                                    <input id={'qty' + menu.id} type="number" placeholder="00" name="quantity" autoComplete="off" className="form-control" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                            <div className="btn-inline">
                                                                <button type="button" className="btn btn-text-secondary"
                                                                    data-bs-dismiss="modal">CANCEL</button>
                                                                <button onClick={(e) => addItem(menu.id, Number(menu.discounted_price), e)} type="button" className="btn btn-text-primary"
                                                                    data-bs-dismiss="modal">ADD</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Add Item Modal */}
                                        </div>))}
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="appBottomMenu d-flex">
                    <div className="col col-6 text-left">
                        <div className="text">
                            <h3>₹ {totalPrice.toFixed(2)}</h3>
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        <button type="submit" id="placeOrderBtn" className="btn btn-primary">
                            <span className="btn btn-primary">Place Order</span>
                        </button>
                    </div>
                </div>
            </form>
            {/* QR Code Payment Modal */}
            <div className="modal fade action-sheet" id="qrCodePanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <div className="iconbox text-primary">
                                    <img src="/assets/img/qrcode.png" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                                </div>
                                <div className="text-center p-2">
                                    <h3>Pay Online</h3>
                                    <p>Scan the QR Code to make payment.</p>
                                </div>
                                <Link className="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Done</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End QR Code Payment */}
            {/* Error Message */}
            <div id="cookiesbox" className={alert.alertClass} style={{ zIndex: "20000000000" }} tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="text-light offcanvas-title">Alert !</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        {alert.alertMessage}
                    </div>
                    <div className="buttons">
                        <button onClick={closeAlert} className="btn btn-light btn-block text-light">Okay</button>
                    </div>
                </div>
            </div>
            {/* End Error Message */}
        </>
    );
}
export default AddOrder;