import { Link } from "react-router-dom";
function ErrorPage() {
    return (
        <>
            <div className="appHeader no-border">
                <div className="left">
                    <Link to={'/dashboard'} className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </Link>
                </div>
                <div className="pageTitle">
                    404 Page
                </div>
                <div className="right">
                </div>
            </div>
            <div id="appCapsule">

                <div className="section">
                    <div className="splash-page mt-5 mb-5">
                        <h1>404</h1>
                        <h2 className="mb-2">Page not found!</h2>
                        <p>
                            The page are you looking for not exists in reality
                        </p>
                    </div>
                </div>

                <div className="fixed-bar">
                    <div className="row">
                        <div className="col-12">
                            <Link to={'/dashboard'} className="btn btn-lg btn-primary btn-block">Go Back</Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default ErrorPage;