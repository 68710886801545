import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import Loader from "../Loader";
import AppHeader from "../AppHeader";
import AppFooter from "../AppFooter";
import TableList from "../table/TableList";
function WaiterProfile() {
    const { waiterId } = useParams();
    const [waiter, setWaiter] = useState({});
    const [tables, setTables] = useState({});
    const [waiterTableLists, setWaiterTableLists] = useState({});
    const [reloadRequired, setReloadRequired] = useState(false);
    const [waiter_id, setWaiterId] = useState(waiterId);
    const [tableId, setTableId] = useState('');
    const [alert, setAlert] = useState({
        alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
        alertMessage: ''
    });
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/waiter-details', {
                waiter_id: waiterId
            }).then(waiterProfileResponse => {
                setWaiter(waiterProfileResponse.data.data);
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/table-list').then(tableListResponse => {
                setTables(tableListResponse.data.data);
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/waiter-tables', {
                waiter_id: waiterId
            }).then(waiterTablesResponse => {
                setWaiterTableLists(waiterTablesResponse.data);
            });
        });
    }, [reloadRequired]);
    const assignTable = (e) => {
        e.preventDefault();
        const assignTableForm = document.getElementById('assignTableForm');
        const assignTableFormData = new FormData(assignTableForm);
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/assign-table', {
                table_id: tableId,
                waiter_id: waiter_id
            }).then(addAssignTableResponse => {
                if (addAssignTableResponse.status === 200) {
                    setAlert(
                        {
                            alertClass: 'z-3 offcanvas bg-success offcanvas-bottom cookies-box show visible',
                            alertMessage: 'Table Assigned'
                        }
                    );
                    setTableId('');
                    document.getElementById('cancelAssignTable').click();
                }
                setReloadRequired(!reloadRequired);
            }).catch(function(error) {
                setAlert(
                    {
                        alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                        alertMessage: 'Something went wrong'
                    }
                );
            });
        });
    }
    const closeAlert = () => {
        setAlert(
            {
                alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
                alertMessage: ''
            }
        );
    }
    return (Object.keys(waiter).length === 0) ? <Loader /> :
        (
            <>
                <AppHeader />
                <div id="appCapsule">
                    <div className="section wallet-card-section pt-1">
                        <div className="wallet-card">
                            <div className="balance">
                                <div className="left">
                                    <h2 className="title pb-0 mb-0">{waiter.fname} {waiter.lname}</h2>
                                    <h4 className="title pb-0 mb-0 text-muted">{waiter.email}</h4>
                                    <h4 className="title mt-0 pt-0 text-muted">{waiter.mobile_no}</h4>
                                </div>
                                <div className="right">
                                    <img style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%' }} src={process.env.REACT_APP_BACKEND_BASE_PATH + '/' + waiter.image} />
                                </div>
                            </div>
                            <div className="wallet-footer">
                                <div className="item">
                                    <Link data-bs-toggle="modal" data-bs-target="#assignTableModal">
                                        <div className="icon-wrapper bg-success w-100">
                                            <ion-icon name="add-outline"></ion-icon>
                                            <strong className="text-white">Assign Table</strong>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Assign Table Modal */}
                    <div className="modal fade action-sheet" id="assignTableModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Assign Table</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form method="POST" action="#" onSubmit={assignTable} id="assignTableForm">
                                            <input type="hidden" name="waiter_id" value={waiter_id} onChange={(e) => { setWaiterId(e.target.value) }} required />
                                            <div className="form-group basic">
                                                <label className="label">Select Table</label>
                                                <div className="input-group mb-2">
                                                    <select className="form-control custom-select" value={tableId} onChange={(e) => { setTableId(e.target.value) }} name="table_id" required>
                                                        <option value="">Select Table</option>
                                                        {(Object.keys(tables).length !== 0) ?
                                                            tables.map((table) =>
                                                                <option key={table.id} value={table.id}>{table.name}</option>
                                                            )
                                                            :
                                                            ('')
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button id="cancelAssignTable" type="button" data-bs-dismiss="modal" className="btn btn-secondary btn-block btn-lg">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Assign Table Modal End */}
                    <div className="section my-3">
                        {Object.keys(waiterTableLists).length === 0 ? '' :
                            <div>
                                <div className="section-title ps-2">Assigned Tables</div>
                                <TableList tableLists={waiterTableLists} />
                            </div>}
                    </div>
                    {/* Alert Modal */}
                    <div id="cookiesbox" className={alert.alertClass} style={{ zIndex: "20000000000" }} tabIndex="-1" data-bs-scroll="true"
                        data-bs-backdrop="false">
                        <div className="offcanvas-header">
                            <h5 className="text-light offcanvas-title">Alert !</h5>
                        </div>
                        <div className="offcanvas-body">
                            <div>
                                {alert.alertMessage}
                            </div>
                            <div className="buttons">
                                <button onClick={closeAlert} className="btn btn-light btn-block text-light">Okay</button>
                            </div>
                        </div>
                    </div>
                    {/* End Alert Modal */}
                </div>
                <AppFooter page="table" />
            </>
        );
}
export default WaiterProfile;