import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import TrendingMenu from "./components/menu/TrandingMenu";
import TableList from "./components/table/TableList";
import Axios from "axios";
import WaiterList from "./components/waiter/WaiterList";
import RecentOrders from "./components/order/RecentOrders";
function Dashboard() {
    const [orders, setOrders] = useState({});
    const [menus, setMenus] = useState({});
    const [tables, setTables] = useState({});
    const [waiters, setWaiters] = useState({});
    const [analytics, setAnalytics] = useState({});
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/recent-orders', {
                admin_id: localStorage.getItem('admin')
            }).then(menuListResponse => {
                setOrders(menuListResponse.data.data);
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/menu-list').then(menuListResponse => {
                setMenus(menuListResponse.data.data.slice(0, 4));
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/table-list', {
                admin_id: localStorage.getItem('admin')
            }).then(tableListResponse => {
                setTables(tableListResponse.data.data.slice(0, 8));
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/waiter-list').then(waiterListResponse => {
                setWaiters(waiterListResponse.data.data.slice(0, 4));
            });
        });
    }, []);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/order-analytics', {
                admin_id: localStorage.getItem('admin')
            }).then(orderAnalyticsResponse => {
                setAnalytics(orderAnalyticsResponse.data);
            });
        });
    }, []);
    return (
        <>
            <AppHeader />
            <div id="appCapsule">
                <div className="section wallet-card-section pt-1">
                    <div className="wallet-card">
                        <div className="balance">
                            <div className="left">
                                <span className="title">Total Sale</span>
                                {(Object.keys(analytics).length !== 0) ? <h1 className="total">₹ {Number(analytics.total_sale).toFixed(2)}</h1> : <h1 className="total">₹ {Number(0).toFixed(2)}</h1>}
                            </div>
                            <div className="right">
                                <div className="button">
                                    <ion-icon name="trending-up-outline"></ion-icon>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-footer">
                            <div className="item">
                                <Link to="/menu">
                                    <div className="icon-wrapper bg-danger">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                    <strong>Menu</strong>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="/table">
                                    <div className="icon-wrapper">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                    <strong>Table</strong>
                                </Link>
                            </div>
                            {(JSON.parse(localStorage.getItem('admin_data')).admin_type === 1)  ?
                                (
                                    < div className="item">
                                        <Link to="/waiter">
                                            <div className="icon-wrapper bg-success">
                                                <ion-icon name="add-outline"></ion-icon>
                                            </div>
                                            <strong>Waiter</strong>
                                        </Link>
                                    </div>
                                )
                               : ''}
                            <div className="item">
                                <Link to="/orders">
                                    <div className="icon-wrapper bg-warning">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                    <strong>Order</strong>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Daily Sale</div>
                                {(Object.keys(analytics).length !== 0) ? <div className="value text-success">₹ {analytics.daily_sale}</div> : <div className="value text-success">₹ {analytics.daily_sale}</div>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Weekly Sale</div>
                                {(Object.keys(analytics).length !== 0) ? <div className="value text-danger">₹ {analytics.weekly_sale}</div> : <div className="value text-danger">₹ {analytics.weekly_sale}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Monthly Sale</div>
                                {(Object.keys(analytics).length !== 0) ? <div className="value text-danger">₹ {analytics.monthly_sale}</div> : <div className="value">₹ {analytics.monthly_sale}</div>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stat-box">
                                <div className="title">Yearly Sale</div>
                                {(Object.keys(analytics).length !== 0) ? <div className="value">₹ {analytics.yearly_sale}</div> : <div className="value">₹ {analytics.yearly_sale}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {(Object.keys(orders).length > 0) ?
                    <div className="section my-4">
                        <div className="section-heading">
                            <h2 className="title">Recent Orders</h2>
                            <Link to="/orders" className="link">View All</Link>
                        </div>
                        <div className="transactions">
                            <RecentOrders orders={orders} />
                        </div>
                    </div>
                    : ''}
                <div className="section my-4">
                    <div className="section-heading">
                        <h2 className="title">Trending Menus</h2>
                        <Link to="/menu" className="link">View All</Link>
                    </div>
                    <div className="transactions">
                        {(Object.keys(menus).length > 0) ? <TrendingMenu menus={menus} /> : ''}
                    </div>
                </div>
                <div className="section my-4">
                    <div className="section-heading">
                        <h2 className="title">Top Selling Tables</h2>
                        <Link to="/table" className="link">View All</Link>
                    </div>
                    <div className="transactions">
                        {(Object.keys(tables).length > 0) ? <TableList tableLists={tables} /> : ''}
                    </div>
                </div>
                <div className="section my-4">
                    <div className="section-heading">
                        <h2 className="title">Recently Joined</h2>
                        <Link to="/waiter" className="link">View All</Link>
                    </div>
                    <div className="transactions">
                        {(Object.keys(waiters).length > 0) ? <WaiterList waiters={waiters} /> : ''}
                    </div>
                </div>
            </div >
            <AppFooter page="dashboard" />
            {/* <SideBar /> */}
        </>
    );
}
export default Dashboard;