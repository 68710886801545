import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Axios from "axios";
import Loader from './components/Loader';
import TableList from "./components/table/TableList";
function Table() {
    const [tables, setTables] = useState({});
    const [tableName, setTableName] = useState('');
    const [relaodRequired, setReloadRequired] = useState(false);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/table-list', {
                admin_id: localStorage.getItem('admin')
            }).then(tableListResponse => {
                setTables(tableListResponse.data.data);
            });
        });
    }, [relaodRequired]);
    const addTable = (e) => {
        e.preventDefault();
        if (tableName !== null && tableName !== '') {
            const APIClient = Axios.create({
                baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            });
            APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/add-table', {
                    name: tableName
                }).then(() => {
                    document.getElementById('cancelAddTableBtn').click();
                    setTableName('');
                    setReloadRequired(!relaodRequired);
                });
            });
        }
    }
    return (Object.keys(tables).length === 0) ?
        (<Loader />) :
        (
            <>
                <AppHeader />
                <div id="appCapsule">
                    <div className="section wallet-card-section pt-1">
                        <div className="wallet-card">
                            <div className="balance">
                                <div className="left">
                                    <span className="title">Total Table</span>
                                    <h1 className="total">{tables.length}</h1>
                                </div>
                                <div className="right">
                                    <div className="button">
                                        <ion-icon name="umbrella-outline"></ion-icon>
                                    </div>
                                </div>
                            </div>
                            {(JSON.parse(localStorage.getItem('admin_data')).admin_type === 1) ?
                                <div className="wallet-footer">
                                    <div className="item">
                                        <Link data-bs-toggle="modal" data-bs-target="#addTableModal">
                                            <div className="icon-wrapper w-100">
                                                <ion-icon name="add-outline"></ion-icon>
                                                <strong className="text-white">Add New Table</strong>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                    ('')
                            }
                        </div>
                    </div>
                    {/* Add Table Modal */}
                    <div className="modal fade action-sheet" id="addTableModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add Table</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form method="POST" action="#" onSubmit={addTable} id="addTableForm">

                                            <div className="form-group basic">
                                                <label className="label">Table No.</label>
                                                <div className="input-group mb-2">
                                                    <input type="number" name="name" value={tableName || ''} onChange={(e) => { setTableName(e.target.value) }} className="form-control" placeholder="Enter table number" autoComplete="off" autoFocus required />
                                                </div>
                                            </div>


                                            <div className="form-group basic">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button id="cancelAddTableBtn" type="button" data-bs-dismiss="modal" className="btn btn-secondary btn-block btn-lg">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Table Modal End */}
                    <TableList tableLists={tables} />
                </div>
                <AppFooter page="table" />
                {/* <SideBar /> */}
            </>
        );
}
export default Table;