import { useState, useEffect, memo } from 'react';
import APIClient from '../APIClient';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState({
        alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
        alertMessage: ''
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        if(localStorage.getItem('admin_data') > 0) {
            navigate('/dashboard');
        }
    }, []);
    const loginAuth = (e) => {
        e.preventDefault();
        if(email.length > 0 && password.length > 0) {
            const payload = {
                email: email,
                password: password
            };
            APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/login',payload).then(loginAuthResponse => {
                    if(loginAuthResponse.data.message === 'Email Not Registered') {
                        setAlert(
                            {
                                alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                alertMessage: 'Invalid Email Address'
                            }
                        );
                    }
                    if(loginAuthResponse.data.message === 'Password not match') {
                        setAlert(
                            {
                                alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                alertMessage: 'Password not match'
                            }
                        );
                    }
                    if(loginAuthResponse.data.message === 'Logged in') {
                        if(loginAuthResponse.data.admin) {
                            localStorage.setItem('token',loginAuthResponse.data.token);
                            localStorage.setItem('admin',loginAuthResponse.data.admin.id);
                            const APIClient = Axios.create({
                                baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
                                withCredentials: true,
                                headers: {
                                    "Accept" : "application/json",
                                    "Content-Type" : "application/json",
                                    "Authorization" : "Bearer " + loginAuthResponse.data.token
                                }
                            });
                            APIClient.get('/sanctum/csrf-cookie').then(response => {
                                APIClient.post('/api/admin-data',{
                                    admin_id: loginAuthResponse.data.admin.id
                                }).then(getAdminDataResponse => {
                                    localStorage.setItem('admin_data',JSON.stringify(getAdminDataResponse.data.data));
                                    navigate('/dashboard')
                                });
                            });
                            
                        }else {
                            setAlert(
                                {
                                    alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                    alertMessage: 'Something went wrong'
                                }
                            );
                        }
                    }
                }).catch(function(error) {
                    setAlert(
                        {
                            alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                            alertMessage: 'Invalid credentials'
                        }
                    );
                });
            });
        }
    }
    const closeAlert = () => {
        setAlert(
            {
                alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
                alertMessage: ''
            }
        );
    }
    return (
        <>
            <div className="appHeader no-border transparent position-absolute">
                <div className="pageTitle"></div>
                <div className="right">
                </div>
            </div>
            <div id="appCapsule">

                <div className="section text-center">
                    <img src='/assets/img/restaurant.png' className="loginPageLogo" alt="Lava Logo"/>
                    <h1>Log in</h1>
                    <h4>Fill the form to log in</h4>
                </div>
                <div className="section">

                    <form action="#" method="POST" onSubmit={loginAuth}>
                        <div className="card">
                            <div className="card-body pb-1">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email1">E-mail</label>
                                        <input type="email" value={email || ''} onChange={(e)=> {setEmail(e.target.value)}} className="form-control" autoComplete='off' autoFocus id="email1" placeholder="Your e-mail"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="password1">Password</label>
                                        <input type="password" value={password || ''} onChange={(e) => {setPassword(e.target.value)}} className="form-control" id="password1" autoComplete="off"
                                            placeholder="Your password" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-links mt-2">
                            <div>Forgot Password?</div>
                        </div>

                        <div className="form-button-group  transparent">
                            <button type="submit" className="btn btn-primary btn-block btn-lg">Log in</button>
                        </div>

                    </form>
                </div>

            </div>
            {/* Alert Modal */}
            <div id="cookiesbox" className={alert.alertClass} style={{ zIndex: "20000000000" }} tabIndex="-1" data-bs-scroll="true"
                data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h5 className="text-light offcanvas-title">Alert !</h5>
                </div>
                <div className="offcanvas-body">
                    <div>
                        {alert.alertMessage}
                    </div>
                    <div className="buttons">
                        <button onClick={closeAlert} className="btn btn-light btn-block text-light">Okay</button>
                    </div>
                </div>
            </div>
            {/* End Alert Modal */}
        </>
    );
}
export default memo(Login);