import { useState } from "react";
import Layout from "./Layout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Menu from "./pages/Menu";
import Table from "./pages/Table";
import Waiter from "./pages/Waiter";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/components/order/OrderDetails";
import EditOrder from "./pages/components/order/EditOrder";
import WaiterProfile from "./pages/components/waiter/WaiterProfile";
import ProfileSettings from "./pages/ProfileSettings";
import ErrorPage from "./pages/ErrorPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddOrder from "./pages/components/order/AddOrder";

function App() {
    const [totalMenu, setTotalMenu] = useState(0);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Login />}/>
                    <Route path="dashboard" element={<Dashboard totalMenu={totalMenu} setTotalMenu={setTotalMenu}/>}/>
                    <Route path="menu" element={<Menu />}/>
                    <Route path="table" element={<Table />}/>
                    <Route path="waiter" element={<Waiter />}/>
                    <Route exact path="waiter/:waiterId" element={<WaiterProfile />}/>
                    <Route path="orders" element={<Orders />}/>
                    <Route exact path="order-details/:orderId" element= {<OrderDetails />} />
                    <Route exact path="edit-order/:orderId" element={<EditOrder />} />
                    <Route exact path="add-order/:tableId/:tableName" element={<AddOrder />}/>
                    <Route path="profile-settings" element={<ProfileSettings />}/>
                    <Route path="*" element={<ErrorPage />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App;