import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from '../Loader';
import axios from "axios";
function OrderDetails() {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    useEffect(() => {
        const APIClient = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/order-details', {
                order_id: orderId
            }).then(orderDetailsResponse => {
                setOrder(orderDetailsResponse.data);
            });
        });
    }, []);
    const printBill = (order_id,e) => {
        window.location.href = process.env.REACT_APP_BACKEND_BASE_PATH+'/print?order_id='+order_id;
        return null;
    }
    return (Object.keys(order).length !== 0) ? (
        <>
            <div className="appHeader">
                <div className="left">
                    <Link to={`/orders`} className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </Link>
                </div>
                <div className="pageTitle">Order Detail</div>
                <div className="right">
                    {/* <Link
                        className="headerButton"
                        data-bs-toggle="modal"
                        data-bs-target="#DialogBasic"
                    >
                        <ion-icon name="trash-outline"></ion-icon>
                    </Link> */}
                    <Link onClick={(e) => printBill(order.order_id,e)} className="headerButton">
                        <ion-icon name="print-outline"></ion-icon>
                    </Link>
                </div>
            </div>
            <div
                className="modal fade dialogbox"
                id="DialogBasic"
                data-bs-backdrop="static"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete</h5>
                        </div>
                        <div className="modal-body">Are you sure?</div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a
                                    href="#"
                                    className="btn btn-text-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    CANCEL
                                </a>
                                <a
                                    href="#"
                                    className="btn btn-text-primary"
                                    data-bs-dismiss="modal"
                                >
                                    DELETE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="appCapsule" className="full-height">
                <div className="section mt-2 mb-2">
                    <div className="listed-detail mt-3">
                        <div className="icon-wrapper">
                            <div className="iconbox">
                                <ion-icon name="shield-checkmark-outline"></ion-icon>
                            </div>
                        </div>
                        <h3 className="text-center mt-2">Order Completed</h3>
                    </div>

                    <ul className="listview transparent simple-listview mt-3">
                        <li>
                            <strong>Order Id</strong>
                            <span className="text-success">#{order.order_id}</span>
                        </li>
                        <li>
                            <strong>Customer</strong>
                            <span>{order.customer}</span>
                        </li>
                        <li>
                            <strong>Table No.</strong>
                            <span>{order.table_no}</span>
                        </li>
                        <li>
                            <strong>Order Date</strong>
                            <span>{order.date}</span>
                        </li>
                        <li>
                            <strong>Order Time</strong>
                            <span>{order.time}</span>
                        </li>
                        <li>
                            <strong>Total Amount</strong>
                            <h3 className="m-0">₹ {Number(order.total).toFixed(2)}</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    ) : <Loader />;
}
export default OrderDetails;
