import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import AddMenu from "./components/menu/AddMenu";
import Axios from "axios";
import Loader from './components/Loader';
import MenuList from "./components/menu/MenuList";
function Menu(props) {
    const [menus, setMenus] = useState({});
    const [reloadRequired, setReloadRequired] = useState(false);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/menu-list').then(menuListResponse => {
                setMenus(menuListResponse.data.data);
                props = menuListResponse.data.data.length;
            });
        });
        setReloadRequired();
    }, [reloadRequired]);
    return (Object.keys(menus).length === 0) ?
        (<Loader />) :
        (
            <>
                <AppHeader />
                <div id="appCapsule">
                    <div className="section wallet-card-section pt-1">
                        <div className="wallet-card">
                            <div className="balance">
                                <div className="left">
                                    <span className="title">Total Menu</span>
                                    <h1 className="total">{menus.length}</h1>
                                </div>
                                <div className="right">
                                    <div className="button">
                                        <ion-icon name="pizza"></ion-icon>
                                    </div>
                                </div>
                            </div>
                            {(JSON.parse(localStorage.getItem('admin_data')).admin_type === 1) ?
                                <div className="wallet-footer">
                                    <div className="item">
                                        <Link data-bs-toggle="modal" data-bs-target="#addMenuPanel">
                                            <div className="icon-wrapper bg-danger w-100">
                                                <ion-icon name="add-outline"></ion-icon>
                                                <strong className="text-white">Add New Menu</strong>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                ('')
                            }

                        </div>
                    </div>
                    <MenuList menuItems={menus} />
                    <AddMenu reloadRequired={reloadRequired} />
                </div>
                <AppFooter page="menu" />
                {/* <SideBar /> */}
            </>
        );
}
export default Menu;