import { Link } from "react-router-dom";
function TableList(props) {
    const listItems = props.tableLists.map((item) =>
        <Link to={`/add-order/${item.id}/${item.name}`} key={item.id} className="col-3 mb-2">
            <div className="table-card badge bg-primary">
                <span>{item.name}</span>
            </div>
        </Link>
    );
    return (
        <>
            <div className="section mt-2">
                <div className="row">
                    {listItems}
                </div>
            </div>
        </>
    );
}
export default TableList;