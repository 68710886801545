import { Link } from "react-router-dom";
function AppFooter(props) {
    return (
        <div className="appBottomMenu">
            <Link to="/dashboard" className={(props.page === 'dashboard') ? "item active" : "item"}>
                <div className="col">
                    <ion-icon name="pie-chart-outline"></ion-icon>
                    <strong>Dashboard</strong>
                </div>
            </Link>
            <Link to="/menu" className={(props.page === 'menu') ? "item active" : "item"}>
                <div className="col">
                    <ion-icon name="document-text-outline"></ion-icon>
                    <strong>Menu</strong>
                </div>
            </Link>
            <Link to="/table" className={(props.page === 'table') ? "item active" : "item"}>
                <div className="col">
                    <ion-icon name="apps-outline"></ion-icon>
                    <strong>Table</strong>
                </div>
            </Link>
            <Link to="/orders" className={(props.page === 'orders') ? "item active" : "item"}>
                <div className="col">
                    <ion-icon name="card-outline"></ion-icon>
                    <strong>Order</strong>
                </div>
            </Link>
            <Link to="/profile-settings" className={(props.page === 'profile-settings') ? "item active" : "item"}>
                <div className="col">
                    <ion-icon name="settings-outline"></ion-icon>
                    <strong>Settings</strong>
                </div>
            </Link>
        </div>
    );
}
export default AppFooter;