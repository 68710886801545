function MenuList(props) {
    const listItems = props.menuItems.map((item) =>
        <div key={item.id} className="col-6 mb-2">
            <div className="blog-card position-relative">
                <img src={process.env.REACT_APP_BACKEND_BASE_PATH + '/' + item.image} alt={item.name} style={{height: "120px", width: "100%", objectFit: "cover"}} className="imaged w-100" />
                <span style={{left: "10px", top: "10px"}} className="badge bg-primary text-light px-2 position-absolute">₹ {item.discounted_price}</span>
                <div className="text">
                    <h4 className="title">{item.name} </h4>
                </div>
            </div>
        </div>
    );
    return (
        <>
            <div className="section my-3">
                <div className="row">
                    {listItems}
                </div>
            </div>
        </>
    );
}
export default MenuList;