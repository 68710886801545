import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppFooter from "./components/AppFooter";
import Loader from '../pages/components/Loader';
import OrderDetails from "./components/order/OrderDetails";
import Axios from "axios";
function Orders() {
    const [orders, setOrders] = useState({});
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/orders', {
                admin_id: localStorage.getItem('admin')
            }).then(orderListResponse => {
                setOrders(orderListResponse.data.data);
            });
        });
    }, []);
    return (Object.keys(orders).length === 0) ? <Loader /> :
        (
            <>
                <div className="appHeader">
                    <div className="left">
                        <Link to="/dashboard" className="headerButton goBack">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                        </Link>
                    </div>
                    <div className="pageTitle">
                        Orders
                    </div>
                </div>
                <div id="appCapsule">
                    {orders.map((order,index) =>
                        <div key={'OrderId'+index} className="section mt-2 mb-4">
                            <div className="section-title">{order.date}</div>
                            <div className="transactions">
                                {order.orders.map((item,index) =>
                                    <div key={item.id+'_'+index} className="item">
                                        <Link to={`/order-details/${item.order_id}`}>
                                            <div className="detail">
                                                <img src={item.image} alt={item.customer} style={{width:'50px', height: '50px', objectFit: 'cover'}} className="image-block imaged w48" />
                                                <div>
                                                    <strong>{item.customer}</strong>
                                                    <p>#{item.order_id}</p>
                                                    <p>Table No: {item.table}</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="right">
                                            <Link to={`/edit-order/${item.order_id}`} className="btn btn-primary btn-md">Edit</Link>
                                            <div className="price text-success">₹ {Number(item.price).toFixed(2)}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <AppFooter page="orders" />
            </>
        );
}
export default Orders;