import { Link } from "react-router-dom";
function RecentOrders(props) {
    const orderLists = props.orders.map((order,index) =>
        <div key={index} className="item">
            <Link to={`/order-details/${order.order_id}`}>
                <div className="detail">
                    <img src={order.image} alt={order.customer} style={{width: '50px', height: '50px', objectFit: 'cover'}} className="image-block imaged w48" />
                    <div>
                        <strong>{order.customer}</strong>
                        <p>#{order.order_id}</p>
                    </div>
                </div>
            </Link>
            <div className="right">
                <Link to={`/edit-order/${order.order_id}`} className="btn btn-primary btn-md">Edit</Link>
                <div className="price text-success">₹ {order.price}</div>
            </div>
        </div>
    );
    return orderLists;
}
export default RecentOrders;