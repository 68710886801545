import { useEffect, useState } from "react";
import Axios from "axios";
function AddMenu(props) {
    const [menuImage, setMenuImage] = useState();
    const [menuName, setMenuName] = useState('');
    const [menuPrice, setMenuPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [menuDescription, setMenuDescription] = useState('');
    const [reloadRequired, setReloadRequired] = useState(props.reloadRequired);
    useEffect(() => {
        setMenuImage('');
        document.getElementById('addMenuImagePrev').src = 'assets/img/placeholder.avif';
        setMenuName('');
        setMenuPrice('');
        setDiscountPrice('');
        setMenuDescription('');  
    },[reloadRequired]);
    const uploadImage = () => {
        document.getElementById('addMenuImage').click();
        document.getElementById('addMenuImage').onchange = evt => {
            const [file] = document.getElementById('addMenuImage').files
            if (file) {
                document.getElementById('addMenuImagePrev').src = URL.createObjectURL(file)
            }
        }
    }
    const addMenu = (e) => {
        e.preventDefault();
        if(menuImage !== undefined && menuImage !== '' && menuImage !== null) {
            const menuForm = document.getElementById('addMenuForm');
            const menuFormData = new FormData(menuForm);
            menuFormData.append("image", menuImage);
            const APIClient = Axios.create({
                baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            });
            APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/add-menu', menuFormData).then(addMenuResponse => {
                    setReloadRequired(!reloadRequired);
                    document.getElementById('closeMenuModal').click();
                });
            });
        }
    }
    return (
        <>
            <div className="modal fade action-sheet" id="addMenuPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-fullscreen" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Menu</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form action="#" method="POST" onSubmit={addMenu} id="addMenuForm" encType="multipart/form-data">
                                    <div className="form-group basic text-center">
                                        <div className="input-wrapper">
                                            <div className="avatar-section">
                                                <img src='assets/img/placeholder.avif' id="addMenuImagePrev" alt="avatar" className="imaged w100 rounded" />
                                                <span className="button" onClick={uploadImage}>
                                                    <ion-icon name="camera-outline"></ion-icon>
                                                </span>
                                                <input id="addMenuImage" onChange={(e) => {setMenuImage(e.target.files[0])}} className="d-none" type="file" accept="image/*" name="image" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="text11d">Menu Name</label>
                                            <input name="name" type="text" value={menuName || ''} onChange={(e) => {setMenuName(e.target.value)}} className="form-control" id="text11d" autoComplete="off" autoFocus placeholder="e.g. Pizza" required />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <label className="label">Regular Price</label>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text" id="basic-addonb1">₹</span>
                                            <input name="regular_price" type="number" value={menuPrice || ''} step="any" className="form-control" autoComplete="off" placeholder="0.00"
                                                onChange={(e) => { setMenuPrice(e.target.value) }} required />
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <label className="label">Discounted Price</label>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text" id="basic-addonb2">₹</span>
                                            <input name="discounted_price" type="number" value={discountPrice || ''} step="any" className="form-control" autoComplete="off" placeholder="0.00"
                                                onChange={(e) => {setDiscountPrice(e.target.value)}} required />
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="text11d">Short Description</label>
                                            <textarea name="description" value={menuDescription || ''} onChange={(e) => {setMenuDescription(e.target.value)}} className="form-control" placeholder="Short description..." maxLength="100" autoComplete="off" required></textarea>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <button id="closeMenuModal" type="button" className="btn btn-secondary btn-block btn-lg"
                                                    data-bs-dismiss="modal">Cancel</button>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <button type="submit" className="btn btn-primary btn-block btn-lg">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddMenu;