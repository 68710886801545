import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppFooter from "./components/AppFooter";
import Axios from "axios";
function ProfileSettings() {
    const [themeMode, setThemeMode] = useState(localStorage.getItem('theme'));
    const [adminData, setAdminData] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        if (themeMode === 'dark-mode') {
            if (!(document.getElementById('darkmodeSwitch').checked)) {
                document.getElementById('darkmodeSwitch').setAttribute('checked', true);
            }
        } else {
            if (document.getElementById('darkmodeSwitch').checked) {
                document.getElementById('darkmodeSwitch').setAttribute('checked', false);
            }
        }
        document.getElementById('themeBody').className = themeMode;
        
    }, [themeMode]);
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/admin-data', {
                admin_id: localStorage.getItem('admin'),
            }).then(adminDataResponse => {
                setAdminData(
                    {
                        admin_image : adminDataResponse.data.data.image
                    }
                );
            });
        });
    },[]);
    const changeTheme = (e) => {
        if (!(document.getElementById('darkmodeSwitch').checked)) {
            localStorage.setItem('theme', '');
            setThemeMode('');
        }
        if (document.getElementById('darkmodeSwitch').checked) {
            localStorage.setItem('theme', 'dark-mode');
            setThemeMode('dark-mode');
        }
    }
    const logout = () => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept" : "application/json",
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+ localStorage.getItem('token')
            }
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/logout', {
                    admin_id: localStorage.getItem('admin')
                }).then(logoutResponse => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('admin');
                    localStorage.removeItem('admin_data');
                    navigate("/");
                }
            );
        });
    }
    if(adminData.length === 0) {
        return 
    }
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <Link to={'/dashboard'} className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </Link>
                </div>
                <div className="pageTitle">
                    Settings
                </div>
                <div className="right">
                    <Link href="app-notifications.html" className="headerButton">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon>
                        <span className="badge badge-danger">4</span>
                    </Link>
                </div>
            </div>
            <div id="appCapsule">
                <div className="section mt-3 text-center">
                    <div className="avatar-section">
                        <Link href="#">
                            <img src={(adminData.admin_image == null) ? '/assets/img/placeholder.avif' : process.env.REACT_APP_BACKEND_BASE_PATH+'/'+adminData.admin_image} alt="avatar" className="imaged w100 rounded" />
                            <span className="button">
                                <ion-icon name="camera-outline"></ion-icon>
                            </span>
                        </Link>
                    </div>
                </div>

                <div className="listview-title mt-1">Theme</div>
                <ul className="listview image-listview text inset no-line">
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Dark Mode
                                </div>
                                <div className="form-check form-switch  ms-2">
                                    <input onChange={(e) => { changeTheme(e) }} className="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch" />
                                    <label className="form-check-label" htmlFor="darkmodeSwitch"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div className="listview-title mt-1">Profile Settings</div>
                <ul className="listview image-listview text inset">
                    <li>
                        <Link className="item">
                            <div className="in">
                                <div>Change Name</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="item">
                            <div className="in">
                                <div>Update E-mail</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="item">
                            <div className="in">
                                <div>Update Phone Number</div>
                            </div>
                        </Link>
                    </li>
                </ul>

                <div className="listview-title mt-1">Security</div>
                <ul className="listview image-listview text mb-2 inset">
                    <li>
                        <Link className="item">
                            <div className="in">
                                <div>Update Password</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={logout} className="item">
                            <div className="in">
                                <div>Log out all devices</div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
            <AppFooter page="profile-settings"/>
        </>
    );
}
export default ProfileSettings;