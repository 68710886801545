import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
function Layout() {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('admin'));
    const navigate = useNavigate();
    useEffect(() => {
        if(!(isLoggedIn && isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn > 0)) {
            navigate('/');
        }
    },[]);
    return (localStorage.getItem('admin') > 0) ? 
    (<Outlet />) : (<Login />);
}
export default Layout;