import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Axios from "axios";
import Loader from './components/Loader';
import WaiterList from "./components/waiter/WaiterList";
function Waiter() {
    const [waiters, setWaiters] = useState({});
    const [waiterImage, setWaiterImage] = useState();
    const [waiterFirstName, setWaiterFirstName] = useState('');
    const [waiterLastName, setWaiterLastName] = useState('');
    const [waiterEmail, setWaiterEmail] = useState('');
    const [waiterPassword, setWaiterPassword] = useState('');
    const [waiterMobileNo, setWaiterMobileNo] = useState('');
    const [relaodRequired, setReloadRequired] = useState(false);
    const [alert, setAlert] = useState({
        alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
        alertMessage: ''
    });
    useEffect(() => {
        const APIClient = Axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
        APIClient.get('/sanctum/csrf-cookie').then(response => {
            APIClient.post('/api/waiter-list').then(waiterListResponse => {
                setWaiters(waiterListResponse.data.data);
            });
        });
    }, [relaodRequired]);
    const uploadImage = () => {
        document.getElementById('addWaiterImage').click();
        document.getElementById('addWaiterImage').onchange = evt => {
            const [file] = document.getElementById('addWaiterImage').files
            if (file) {
                document.getElementById('addWaiterImagePrev').src = URL.createObjectURL(file)
            }
        }
    }
    const addWaiter = (e) => {
        e.preventDefault();
        if (waiterImage !== undefined && waiterImage !== '' && waiterImage !== null) {
            const waiterForm = document.getElementById('addWaiterForm');
            const waiterFormData = new FormData(waiterForm);
            waiterFormData.append("image", waiterImage);
            const APIClient = Axios.create({
                baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            });
            APIClient.get('/sanctum/csrf-cookie').then(response => {
                APIClient.post('/api/add-waiter', waiterFormData).then(addWaiterResponse => {                    
                    if((addWaiterResponse.data.status === 'Email Already Taken') || (addWaiterResponse.data.status === 'Phone Number Already Taken')) {
                        if(addWaiterResponse.data.status === 'Email Already Taken') {
                            setAlert(
                                {
                                    alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                    alertMessage: 'Email already taken'
                                }
                            );
                        }
                        if(addWaiterResponse.data.status === 'Phone Number Already Taken') {
                            setAlert(
                                {
                                    alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                                    alertMessage: 'Phone Number Already Taken'
                                }
                            );
                        }
                    }else {
                        document.getElementById('cancelAddWaiterBtn').click();
                        document.getElementById('addWaiterImagePrev').src = 'assets/img/placeholder.avif';
                        setWaiterImage();
                        setWaiterFirstName('');
                        setWaiterLastName('');
                        setWaiterEmail('');
                        setWaiterPassword('');
                        setWaiterMobileNo('');
                        setReloadRequired(!relaodRequired);
                    }
                });
            });
        }else {
            setAlert(
                {
                    alertClass: 'z-3 offcanvas bg-danger offcanvas-bottom cookies-box show visible',
                    alertMessage: 'Please upload image'
                }
            );
        }
    }
    const closeAlert = () => {
        setAlert(
            {
                alertClass: 'offcanvas bg-danger offcanvas-bottom cookies-box invisible',
                alertMessage: ''
            }
        );
    }
    return (Object.keys(waiters).length === 0) ?
        (<Loader />) :
        (
            <>
                <AppHeader />
                <div id="appCapsule">
                    <div className="section wallet-card-section pt-1">
                        <div className="wallet-card">
                            <div className="balance">
                                <div className="left">
                                    <span className="title">Total Waiter</span>
                                    <h1 className="total">{waiters.length}</h1>
                                </div>
                                <div className="right">
                                    <div className="button">
                                        <ion-icon name="people-circle-outline"></ion-icon>
                                    </div>
                                </div>
                            </div>
                            <div className="wallet-footer">
                                <div className="item">
                                    <Link data-bs-toggle="modal" data-bs-target="#addWaiterModal">
                                        <div className="icon-wrapper bg-success w-100">
                                            <ion-icon name="add-outline"></ion-icon>
                                            <strong className="text-white">Add New Waiter</strong>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Waiter Modal */}
                    <div className="modal fade action-sheet" id="addWaiterModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog position-relative" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add Waiter</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content" style={{ height: "100%" }}>
                                        <form method="POST" action="#" onSubmit={addWaiter} id="addWaiterForm">
                                            <div className="form-group basic text-center">
                                                <div className="input-wrapper">
                                                    <div className="avatar-section">
                                                        <img src='assets/img/placeholder.avif' id="addWaiterImagePrev" alt="avatar" className="imaged w100 rounded" />
                                                        <span className="button" onClick={uploadImage}>
                                                            <ion-icon name="camera-outline"></ion-icon>
                                                        </span>
                                                        <input id="addWaiterImage" onChange={(e) => { setWaiterImage(e.target.files[0]) }} className="d-none" accept="image/*" type="file" name="image" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <label className="label">First Name</label>
                                                <div className="input-group mb-2">
                                                    <input type="text" name="fname" value={waiterFirstName || ''} onChange={(e) => { setWaiterFirstName(e.target.value) }} className="form-control" placeholder="First name" autoComplete="off" autoFocus required />
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <label className="label">Last Name</label>
                                                <div className="input-group mb-2">
                                                    <input type="text" name="lname" value={waiterLastName || ''} onChange={(e) => { setWaiterLastName(e.target.value) }} className="form-control" placeholder="Last name" autoComplete="off" required />
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <label className="label">Email</label>
                                                <div className="input-group mb-2">
                                                    <input type="email" name="email" value={waiterEmail || ''} onChange={(e) => { setWaiterEmail(e.target.value) }} className="form-control" placeholder="e.g. abc@gmail.com" autoComplete="off" required />
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <label className="label">Password</label>
                                                <div className="input-group mb-2">
                                                    <input type="password" name="password" value={waiterPassword || ''} onChange={(e) => { setWaiterPassword(e.target.value) }} className="form-control" placeholder="********" autoComplete="off" required />
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <label className="label">Phone Number</label>
                                                <div className="input-group mb-2">
                                                    <input type="number" name="mobile_no" value={waiterMobileNo || ''} onChange={(e) => { setWaiterMobileNo(e.target.value) }} className="form-control" placeholder="Enter Phone Number" autoComplete="off" required />
                                                </div>
                                            </div>


                                            <div className="form-group basic">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button id="cancelAddWaiterBtn" type="button" data-bs-dismiss="modal" className="btn btn-secondary btn-block btn-lg">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Waiter Modal End */}
                    <div className="section my-3">
                        <WaiterList waiters={waiters} />
                    </div>
                </div>
                {/* Error Message */}
                <div id="cookiesbox" className={alert.alertClass} style={{zIndex: "20000000000"}} tabIndex="-1" data-bs-scroll="true"
                    data-bs-backdrop="false">
                    <div className="offcanvas-header">
                        <h5 className="text-light offcanvas-title">Attention !</h5>
                    </div>
                    <div className="offcanvas-body">
                        <div>
                            {alert.alertMessage}
                        </div>
                        <div className="buttons">
                            <button onClick={closeAlert} className="btn btn-light btn-block text-light">Okay</button>
                        </div>
                    </div>
                </div>
                {/* End Error Message */}
                <AppFooter page="" />
                {/* <SideBar /> */}
            </>
        );
}
export default Waiter;