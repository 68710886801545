import Axios from "axios";

const APIClient = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
    withCredentials: true,
    headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
    "Authorization": "Bearer "
	},
});
export default APIClient;
